<template>
  <v-card
    v-cloak
    class="FileBox dropbox"
    elevation="0"
    :style="`background: ${dragover ? 'lightblue' : 'lightcyan'}`"
    @drop.prevent="addDropFile"
    @dragover.prevent="dragover = true"
    @dragenter.prevent="dragover = true"
    @dragleave.prevent="dragover = false"
    @click="
      () => {
        if (!loading) $refs.filebtn.$refs.input.click()
      }
    "
  >
    <v-card-text v-if="!loading">
      <ERow class="d-flex flex-column" dense align="center" justify="center">
        <v-icon :class="[dragover ? 'mt-2 mb-6' : 'my-4']" size="60">
          fa-cloud-arrow-up
        </v-icon>
        <h4>Drop your file(s) here, or click to select them.</h4>
        <h5 class="primary--text">
          Supported Format(s): {{ displayAllowedTypes }}
        </h5>
        <v-file-input
          v-show="false"
          ref="filebtn"
          v-model="ingestFileUploaderStore.files"
          :multiple="
            ingestFileUploaderStore.selectedFileType == 'images' ||
            ingestFileUploaderStore.selectedFileType == 'videos'
          "
          :clearable="false"
          :accept="allowedTypes"
          :disabled="disabled"
          @change="onSelect"
        >
        </v-file-input>
      </ERow>
    </v-card-text>
    <v-card-text v-else>
      <ECol
        v-if="
          ingestFileUploaderStore.uploadStats &&
          ingestFileUploaderStore.uploadStats.isUploading
        "
        class="text-center"
      >
        <v-progress-linear
          v-model="ingestFileUploaderStore.uploadStats.percentage"
          :height="25"
        >
          <template #default="{ value }">
            <strong> Progress: {{ Math.ceil(value) }}% </strong>
          </template>
        </v-progress-linear>
        <h4 class="mt-2">
          Your file(s) are being uploaded. This may take a while.
        </h4>
      </ECol>
      <ECol
        v-else-if="uploadStats && uploadStats.isProcessing"
        class="text-center"
      >
        <v-progress-circular
          indeterminate
          color="primary"
          size="60"
          width="7"
        />
        <h4 class="mt-4">
          Finalizing processing for ({{ ingestFileUploaderStore.files.length }})
          file(s)...
        </h4>
      </ECol>
      <ECol v-else class="text-center">
        <v-icon class="mt-2 mb-4" size="60"> fa-check-to-slot </v-icon>
        <h4>({{ ingestFileUploaderStore.files.length }} files) selected</h4>
        <h5 class="mt-2 primary--text">
          {{ totalSizeLabel }}
        </h5>
      </ECol>
    </v-card-text>
  </v-card>
</template>

<script>
import { IMAGE_TYPES } from "@evercam/shared/constants/ingest"
import { useIngestFileUploaderStore } from "@/stores/ingestFileUploader"
import { mapStores } from "pinia"

export default {
  props: {
    allowedTypes: {
      type: Array,
      default: () => IMAGE_TYPES,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    dragover: false,
    loading: false,
  }),
  computed: {
    ...mapStores(useIngestFileUploaderStore),
    totalSizeLabel() {
      return `Total Size: ${this.$units.formatBytes(
        this.totalSizeValue()
      )}`.toUpperCase()
    },
    displayAllowedTypes() {
      return this.allowedTypes.toString().replaceAll(",", ", ").toUpperCase()
    },
  },
  watch: {
    "ingestFileUploaderStore.files"(value) {
      if (!value || value.length == 0) {
        this.loading = false
      }
    },
  },
  methods: {
    addDropFile(e) {
      if (this.disabled) {
        return
      }
      this.selectAllowedFiles(e.dataTransfer.files)
    },
    onSelect() {
      this.selectAllowedFiles(this.ingestFileUploaderStore.files)
    },
    selectAllowedFiles(originalFiles) {
      let files = []
      //Check if this is not an array or list
      if (!Array.isArray(originalFiles) && !originalFiles.length) {
        originalFiles = [originalFiles]
      }
      Array.from(originalFiles).forEach((elt) => {
        if (this.isFileAllowed(elt)) {
          files.push(elt)
        }
      })
      this.ingestFileUploaderStore.files = files
      this.loading = true
      this.ingestFileUploaderStore.uploadStats.totalSize = this.totalSizeValue()
    },
    isFileAllowed(file) {
      return this.allowedTypes.includes(
        `.${file.name.split(".").pop().toLowerCase()}`
      )
    },
    totalSizeValue() {
      return this.ingestFileUploaderStore.files.reduce(
        (partialSize, item) => partialSize + item.size,
        0
      )
    },
  },
}
</script>

<style lang="scss">
.FileBox {
  flex: 1;
}

.dropbox {
  outline: 2px dashed grey;
  outline-offset: -10px;
  background: lightcyan;
  color: dimgray;
  padding: 10px 10px;
  min-height: 150px;
  height: 150px;
  position: relative;
  cursor: pointer;
}
.input-file {
  opacity: 0;
  width: 100%;
  height: 150px;
  position: absolute;
  cursor: pointer;
}
.dropbox:hover {
  background: lightblue !important;
}

.dropbox p {
  font-size: 1.2em;
  text-align: center;
  padding: 25px 0;
}
</style>
