<template>
  <ERow id="upload-panel" no-gutters justify="center">
    <ECol class="ma-2">
      <v-text-field
        v-if="uploadType === assets360Types.route"
        v-model="targetPointsCount"
        label="Target point count"
        type="number"
        step="1"
        class="mx-2"
        outlined
        dense
      />
      <FileBox
        :allowedTypes="allowedTypes"
        :disabled="
          ingestFileUploaderStore.uploadStats &&
          ingestFileUploaderStore.uploadStats.isUploading
        "
      />

      <v-card-actions class="my-2">
        <v-spacer />
        <v-btn
          color="white grey--text"
          elevation="0"
          :disabled="
            ingestFileUploaderStore.uploadStats &&
            ingestFileUploaderStore.uploadStats.isUploading
          "
          @click="clearForm()"
        >
          Clear
        </v-btn>
        <v-btn
          color="primary"
          :disabled="
            areFilesEmpty ||
            (ingestFileUploaderStore.uploadStats &&
              ingestFileUploaderStore.uploadStats.isUploading)
          "
          @click="processUpload"
        >
          Upload
        </v-btn>
      </v-card-actions>
    </ECol>
  </ERow>
</template>

<script>
import {
  ALLOWED_IMAGES_TYPES,
  ALLOWED_ROUTE_TYPES,
  ALLOWED_MODEL_TYPES,
  AUDIO_TYPES,
  INSTA_360_TYPES,
} from "@evercam/shared/constants/ingest"
import FileBox from "@/components/ingest/FileBox"
import * as tus from "tus-js-client/dist/tus.min.js"
import { ASSETS_360_TYPES } from "@/components/constants"
import { IngestApi } from "@evercam/shared/api/ingestApi"
import { mapStores } from "pinia"
import { useAccountStore } from "@/stores/account"
import { useIngestFileUploaderStore } from "@/stores/ingestFileUploader"

export default {
  components: {
    FileBox,
  },
  props: {
    uploadType: {
      type: String,
      default: "",
    },
    id: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    uploadedUrls: [],
    assets360Types: ASSETS_360_TYPES,
    targetPointsCount: 0,
  }),
  computed: {
    ...mapStores(useAccountStore, useIngestFileUploaderStore),
    allowedTypes() {
      switch (this.uploadType) {
        case ASSETS_360_TYPES.image:
          return ALLOWED_IMAGES_TYPES
        case ASSETS_360_TYPES.model:
          return ALLOWED_MODEL_TYPES
        case ASSETS_360_TYPES.route:
          return ALLOWED_ROUTE_TYPES
        case ASSETS_360_TYPES.video:
          return INSTA_360_TYPES
        case ASSETS_360_TYPES.mobilePhoto:
          return ALLOWED_IMAGES_TYPES
        case ASSETS_360_TYPES.mobileAudio:
          return AUDIO_TYPES
        default:
          return []
      }
    },
    areFilesEmpty() {
      return this.ingestFileUploaderStore.files.length === 0
    },
  },
  mounted() {
    this.ingestFileUploaderStore.selectedFileType = "videos"
    this.ingestFileUploaderStore.files = []
  },
  methods: {
    async processUpload() {
      if (this.areFilesEmpty) {
        this.$notifications.error("Please provide files to upload")

        return
      }

      try {
        this.ingestFileUploaderStore.uploadStats.isUploading = true
        this.ingestFileUploaderStore.uploadStats.percentage = 0
        for (let i = 0; i < this.ingestFileUploaderStore.files.length; i++) {
          this.uploadedUrls.push(
            await this.uploadFile(this.ingestFileUploaderStore.files[i])
          )
        }
        this.uploadToPortal()
      } catch (e) {
        this.$notifications.error(`Couldn't upload files : ${e}`)
      }
    },
    async uploadFile(file) {
      return new Promise((resolve, reject) => {
        const upload = new tus.Upload(file, {
          endpoint: `${this.$config.public.portalURL}`,
          chunkSize: Infinity,
          retryDelays: [0, 1000, 3000, 5000],
          parallelUploads: 1,
          metadata: {
            filename: file.name,
            filetype: file.type,
          },
          onProgress: (bytesUploaded) => {
            this.ingestFileUploaderStore.uploadStats.percentage = (
              ((this.ingestFileUploaderStore.uploadStats.uploadedSize +
                bytesUploaded) /
                this.ingestFileUploaderStore.uploadStats.totalSize) *
              100
            ).toFixed(2)
          },
          onSuccess: () => {
            this.ingestFileUploaderStore.uploadStats.uploadedSize += file.size
            this.ingestFileUploaderStore.uploadStats.percentage = (
              (this.ingestFileUploaderStore.uploadStats.uploadedSize /
                this.ingestFileUploaderStore.uploadStats.totalSize) *
              100
            ).toFixed(2)

            file.uploadPercentage = "100"
            const lastDotIndex = upload.file.name.lastIndexOf(".")
            const title = upload.file.name.substring(0, lastDotIndex)
            const fileExtension = upload.file.name.substring(lastDotIndex + 1)
            resolve({
              url: upload.url,
              title,
              fileExtension,
            })
          },
          onError: (error) => {
            console.log("Failed because: " + error)
            reject(error)
          },
        })
        upload.findPreviousUploads().then((previousUploads) => {
          // Found previous uploads so we select the first one.
          if (previousUploads.length) {
            upload.resumeFromPreviousUpload(previousUploads[0])
          }

          // Start the upload
          upload.start()
        })
      })
    },
    uploadToPortal() {
      this.ingestFileUploaderStore.uploadStats.isProcessing = true
      this.ingestFileUploaderStore.uploadStats.isUploading = false
      IngestApi.assets360
        .upload(
          this.id,
          { uploadedBy: this.accountStore.email },
          {
            assetType: this.uploadType,
            upload: this.uploadedUrls,
            targetPointsCount: this.targetPointsCount,
          }
        )
        .then(() => {
          this.ingestFileUploaderStore.uploadStats = {
            percentage: 0,
            totalSize: 0,
            uploadedSize: 0,
            isUploading: false,
            isProcessing: false,
          }
          this.uploadedUrls = []
          this.$emit("upload-completed")
        })
        .catch((e) => {
          this.$notifications.error("Error uploading images", e)
          this.uploadedUrls = []
        })

      return
    },
    clearForm() {
      this.ingestFileUploaderStore.files = []
      this.floor = ""
      this.date = ""
    },
  },
}
</script>

<style lang="scss">
#upload-panel {
  z-index: 2 !important;
}
</style>
