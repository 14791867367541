var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"FileBox dropbox",style:(`background: ${_vm.dragover ? 'lightblue' : 'lightcyan'}`),attrs:{"elevation":"0"},on:{"drop":function($event){$event.preventDefault();return _vm.addDropFile.apply(null, arguments)},"dragover":function($event){$event.preventDefault();_vm.dragover = true},"dragenter":function($event){$event.preventDefault();_vm.dragover = true},"dragleave":function($event){$event.preventDefault();_vm.dragover = false},"click":() => {
      if (!_vm.loading) _vm.$refs.filebtn.$refs.input.click()
    }}},[(!_vm.loading)?_c('v-card-text',[_c('ERow',{staticClass:"d-flex flex-column",attrs:{"dense":"","align":"center","justify":"center"}},[_c('v-icon',{class:[_vm.dragover ? 'mt-2 mb-6' : 'my-4'],attrs:{"size":"60"}},[_vm._v("\n        fa-cloud-arrow-up\n      ")]),_vm._v(" "),_c('h4',[_vm._v("Drop your file(s) here, or click to select them.")]),_vm._v(" "),_c('h5',{staticClass:"primary--text"},[_vm._v("\n        Supported Format(s): "+_vm._s(_vm.displayAllowedTypes)+"\n      ")]),_vm._v(" "),_c('v-file-input',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],ref:"filebtn",attrs:{"multiple":_vm.ingestFileUploaderStore.selectedFileType == 'images' ||
          _vm.ingestFileUploaderStore.selectedFileType == 'videos',"clearable":false,"accept":_vm.allowedTypes,"disabled":_vm.disabled},on:{"change":_vm.onSelect},model:{value:(_vm.ingestFileUploaderStore.files),callback:function ($$v) {_vm.$set(_vm.ingestFileUploaderStore, "files", $$v)},expression:"ingestFileUploaderStore.files"}})],1)],1):_c('v-card-text',[(
        _vm.ingestFileUploaderStore.uploadStats &&
        _vm.ingestFileUploaderStore.uploadStats.isUploading
      )?_c('ECol',{staticClass:"text-center"},[_c('v-progress-linear',{attrs:{"height":25},scopedSlots:_vm._u([{key:"default",fn:function({ value }){return [_c('strong',[_vm._v(" Progress: "+_vm._s(Math.ceil(value))+"% ")])]}}],null,false,613426763),model:{value:(_vm.ingestFileUploaderStore.uploadStats.percentage),callback:function ($$v) {_vm.$set(_vm.ingestFileUploaderStore.uploadStats, "percentage", $$v)},expression:"ingestFileUploaderStore.uploadStats.percentage"}}),_vm._v(" "),_c('h4',{staticClass:"mt-2"},[_vm._v("\n        Your file(s) are being uploaded. This may take a while.\n      ")])],1):(_vm.uploadStats && _vm.uploadStats.isProcessing)?_c('ECol',{staticClass:"text-center"},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary","size":"60","width":"7"}}),_vm._v(" "),_c('h4',{staticClass:"mt-4"},[_vm._v("\n        Finalizing processing for ("+_vm._s(_vm.ingestFileUploaderStore.files.length)+")\n        file(s)...\n      ")])],1):_c('ECol',{staticClass:"text-center"},[_c('v-icon',{staticClass:"mt-2 mb-4",attrs:{"size":"60"}},[_vm._v(" fa-check-to-slot ")]),_vm._v(" "),_c('h4',[_vm._v("("+_vm._s(_vm.ingestFileUploaderStore.files.length)+" files) selected")]),_vm._v(" "),_c('h5',{staticClass:"mt-2 primary--text"},[_vm._v("\n        "+_vm._s(_vm.totalSizeLabel)+"\n      ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }